
/** php端接口 (原有接口调用方式太繁琐，从3.7.3版本起采用此方法调用)*/
import { phpBaseUrl } from "@/utils/anyUrl.js";
import request from '@/utils/request.js';
var BaseUrl = phpBaseUrl;


// 上传图片
export function uploadImage(data) {
    return request({
        url: BaseUrl + "/basis/upload",
        method: "post",
        data
    });
}

/** 结算中心************ */
// 业务员佣金（万通）-业务员佣金打款记录列表
export function salesmanlist(params) {
    return request({
        url: BaseUrl + `/salesmanCommisionProof/list`,
        method: "get",
        params
    });
}
// 业务员佣金（万通）-业务员佣金打款记录导出
export function salesmanlistExport(params) {
    return request({
        url: BaseUrl + `/salesmanCommisionProof/listExport`,
        method: "get",
        params
    });
}
// 业务员佣金（万通）-业务员佣金明细
export function salesmandetail(params) {
    return request({
        url: BaseUrl + `/salesmanCommisionProof/detail`,
        method: "get",
        params
    });
}
// 平台提现记录-列表
export function platformWithdrawRecordlist(data) {
    return request({
        url: BaseUrl + `/platformWithdrawRecord/list`,
        method: "post",
        data
    });
}
/** 会员中心************ */
// 会员列表-切换平台资质类型
export function togglePlatformQualification(data) {
    return request({
        url: BaseUrl + `/User/togglePlatformQualification`,
        method: "post",
        data
    });
}
// 会员中心-切换会员经营范围是否同步
export function switchSyncstatus(data) {
    return request({
        url: BaseUrl + `/user/switchSyncstatus`,
        method: "post",
        data
    });
}

/** 白条管理************ */
// 保理白条客户授信记录列表
export function factoringCreditlist(params) {
    return request({
        url: BaseUrl + `/factoringCredit/list`,
        method: "get",
        params
    });
}
// 授信客户详情
export function factoringCreditdetail(params) {
    return request({
        url: BaseUrl + `/factoringCredit/detail`,
        method: "get",
        params
    });
}
// 补充资料
export function factoringCreditreplenish(data) {
    return request({
        url: BaseUrl + `/factoringCredit/replenish`,
        method: "post",
        data
    });
}
// 作废申请
export function factoringCreditcancel(data) {
    return request({
        url: BaseUrl + `/factoringCredit/cancel`,
        method: "post",
        data
    });
}
//获取征信授权书等签约地址
export function getSignUrl(params) {
    return request({
        url: BaseUrl + `/factoringCredit/getSignUrl`,
        method: "get",
        params
    });
}
//获取进件审核状态下拉列表
export function getStatusDropDown(params) {
    return request({
        url: BaseUrl + `/factoringCredit/getStatusDropDown`,
        method: "get",
        params
    });
}
//获取保理进件资料
export function obtainFactoringAccountInfo(params) {
    return request({
        url: BaseUrl + `/factoringCredit/obtainFactoringAccountInfo`,
        method: "get",
        params
    });
}

/** 对账管理************ */
// 白条对账单列表
export function billList(params) {
    return request({
        url: BaseUrl + `/factoring/billList`,
        method: "get",
        params
    });
}
//三方对账
//三方对账-列表
export function statementList(params) {
    return request({
        url: BaseUrl + `/threePartiesStatement/statementList`,
        method: "get",
        params
    });
}
//三方对账-手动解冻
export function unfreeze(data) {
    return request({
        url: BaseUrl + `/threePartiesStatement/unfreeze`,
        method: "post",
        data
    });
}

/** 商品中心************ */
// 商品资料库-商品资料详情
export function platformGoodsdetail(params) {
    return request({
        url: BaseUrl + '/goods/platformGoods/detail',
        method: "get",
        params
    });
}
// 商品资料库-商品资料更新图片
export function updatePicture(data) {
    return request({
        url: BaseUrl + '/goods/platformGoods/updatePicture',
        method: "post",
        data
    });
}

/** 审核中心************ */
// 商品资料审核列表
export function platformGoodsAuditlist(params) {
    return request({
        url: BaseUrl + '/goods/platformGoodsAudit/list',
        method: "get",
        params
    });
}
// 商品资料审核详情
export function platformGoodsAuditdetail(params) {
    return request({
        url: BaseUrl + '/goods/platformGoodsAudit/detail',
        method: "get",
        params
    });
}
// 商品资料审核
export function platformGoodsAuditaudit(data) {
    return request({
        url: BaseUrl + '/goods/platformGoodsAudit/audit',
        method: "post",
        data
    });
}
// 商品文件审核列表
export function getGoodsImportRecordList(params) {
    return request({
        url: BaseUrl + '/goods/platformGoods/getGoodsImportRecordList',
        method: "get",
        params
    });
}
// 商品审核编辑-审核明细列表
export function getGoodsImportAuditList(params) {
    return request({
        url: BaseUrl + '/goods/platformGoods/getGoodsImportAuditList',
        method: "get",
        params
    });
}
// 商品审核编辑-审核状态切换
export function goodsImportAuditToggle(data) {
    return request({
        url: BaseUrl + '/goods/platformGoods/goodsImportAuditToggle',
        method: "post",
        data
    });
}
// 商品审核编辑-替换状态切换（图片或说明书）
export function goodsImportAuditReplaceGoodsImage(data) {
    return request({
        url: BaseUrl + '/goods/platformGoods/goodsImportAuditReplaceGoodsImage',
        method: "post",
        data
    });
}
// 商品审核编辑-获取对码商品列表
export function getGoodsImportAuditGoodsList(params) {
    return request({
        url: BaseUrl + '/goods/platformGoods/getGoodsImportAuditGoodsList',
        method: "get",
        params
    });
}
// 商品审核编辑-获取商品详情
export function getGoodsImportAuditGoodsDetail(params) {
    return request({
        url: BaseUrl + '/goods/platformGoods/getGoodsImportAuditGoodsDetail',
        method: "get",
        params
    });
}
// 商品审核编辑-保存
export function saveGoodsImportAudit(data) {
    return request({
        url: BaseUrl + '/goods/platformGoods/saveGoodsImportAudit',
        method: "post",
        data
    });
}
// 商品审核编辑-更新对码
export function goodsImportAuditGoodsCode(data) {
    return request({
        url: BaseUrl + '/goods/platformGoods/goodsImportAuditGoodsCode',
        method: "post",
        data
    });
}
// 商品审核编辑-审核提交
export function goodsImportAuditSubmit(data) {
    return request({
        url: BaseUrl + '/goods/platformGoods/goodsImportAuditSubmit',
        method: "post",
        data
    });
}
// 商品文件审核详情
export function getGoodsImportAuditGoods(params) {
    return request({
        url: BaseUrl + '/goods/platformGoods/getGoodsImportAuditGoods',
        method: "get",
        params
    });
}
// 获取经营类别
export function basisscope(params) {
    return request({
        url: BaseUrl + '/basis/scope',
        method: "get",
        params
    });
}
// 获取商品分类
export function categoryList(params) {
    return request({
        url: BaseUrl + '/goods/category/list',
        method: "get",
        params
    });
}

/** 基础设置************ */
// 全局配置-举报电话获取
export function getValue(params) {
    return request({
        url: BaseUrl + '/config/baseConfig/getValue',
        method: "get",
        params
    });
}
// 全局配置-举报电话设置
export function setValue(data) {
    return request({
        url: BaseUrl + '/config/baseConfig/setValue',
        method: "post",
        data
    });
}